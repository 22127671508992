<template>
  <abnormal-warning :datas="[4, 'viewDoseException', 'editDoseException']"></abnormal-warning>
</template>
<script>
import AbnormalWarning from '@/components/threshold/AbnormalWarning.vue'
export default {
  components: {
    AbnormalWarning,
  },
  data() {
    return {}
  },
}
</script>
